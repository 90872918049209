import {useIntl} from "gatsby-plugin-react-intl";
import {Layout} from "../components/Layout";
import {BlockTitle} from "../components/BlockTitle";
import React from "react";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {ConsultationForm} from "../components/ConsultationForm";
import {MarkdownRenderer} from "../components/MarkdownRenderer";
import {TypographyV2} from "../components/TypographyV2";
import {SVGSupportImg} from "../components/SVGSupportImg";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {Card} from "../components/Card";

const styles = require('./consultation.module.scss');

export const query = graphql`query ConsultationPage($locale: String!) {
    page: strapiConsultationPage(locale: {eq: $locale}) {
        title
        intro {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        content {
            ...SectionFreeText
        }
        logos {
            ...SectionLogos
        }
        contact {
            ...SectionContactPerson
        }
        seo {
            ...Seo
        }
    }
}
`
export default function ConsultationPage({data, pageContext}) {
    const intl = useIntl();

    return <Layout oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   seo={data.page.seo}
                   title={intl.formatMessage({id: 'menu.consultation'})}
                   companyData={pageContext.companyData}
                   services={pageContext.services}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper className={styles.firstSection}>
            <div className={styles.firstSectionColumn}>
                <div>
                    <BlockTitle noTopMargin={true}>{data.page.title}</BlockTitle>
                    <MarkdownRenderer reduceHeaderVariantBy={3} reduceHeaderLevelBy={2} content={data.page.intro.data}
                                      isDocumentTypography/>
                </div>
                <div>
                    <TypographyV2 tag="h2" variant="h4">{intl.formatMessage({id: 'consultation.title'})}</TypographyV2>
                    <ConsultationForm id="hero-card-consultation-form"/>
                </div>
            </div>
            <Card className={styles.firstSectionColumn}>
                <div className={styles.contectSection}>
                    <TypographyV2 tag="h2" variant="h4">{data.page.content.title}</TypographyV2>
                    <MarkdownRenderer reduceHeaderVariantBy={4} reduceHeaderLevelBy={2}
                                      content={data.page.content.content.data}
                                      isDocumentTypography/>
                </div>

                <div className={styles.logosSection}>
                    <TypographyV2 tag="h2" variant="h5"
                                  className={styles.logosSectionTitle}>{data.page.logos.title}</TypographyV2>
                    <div className={styles.logosSectionLogosWrapper}>
                        {(data.page.logos.items || [])
                            .filter(x => x.localFile)
                            .map((x, i) => {
                                return <SVGSupportImg className={styles.logo} key={i} {...x.localFile}
                                                      gatsbyImgProps={{objectFit: "contain"}}/>
                            })}
                    </div>
                </div>
            </Card>
        </PageMainColumnWrapper>

        <ContactPersonBlock
            title={data.page.contact.title}
            mobileImage={data.page.contact.contactPerson.mobileBigImage.localFile}
            image={data.page.contact.contactPerson.bigImage.localFile}
            person={{
                phone: data.page.contact.contactPerson.phone,
                email: data.page.contact.contactPerson.email,
                name: data.page.contact.contactPerson.name,
                position: data.page.contact.contactPerson.position
            }}
        >
            <MarkdownRenderer content={data.page.contact.content.data}/>
        </ContactPersonBlock>
    </Layout>;
}
